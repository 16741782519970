import React from 'react'
import PageLink from 'components/PageLink'
import { strings } from 'js/strings'
import { isObjectEmpty } from 'js/utils'

export default function StickyCard({ content }) {
  let card = {
    title: strings.stickyCardTitle,
    excerpt: strings.stickyCardExcerpt,
    pageLinkText: strings.stickyCardLinkText,
    pageLink: {
      link: '/contact-us/',
      nodeType: 'Page',
    },
  }
  if (content && !isObjectEmpty(content.stickyCard)) {
    card = content.stickyCard
  }

  return (
    <section className='sticky-card'>
      <h3 className='sticky-card__title'>{card.title}</h3>
      <p className='sticky-card__excerpt'>{card.excerpt}</p>
      {card.pageLink && (
        <PageLink className='sticky-card__cta' pageLink={card.pageLink}>
          {card.pageLinkText}
        </PageLink>
      )}
    </section>
  )
}
