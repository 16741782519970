import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ImageWrap from 'components/ImageWrap'
import StickyCard from 'components/StickyCard'
import HubspotFormWrap from 'components/HubspotFormWrap'
import Layout from 'components/Layout'
import PostTitleBlock from 'components/PostTitleBlock'
import SimplePost from 'components/SimplePost'
import { isObjectEmpty } from 'js/utils'

export default function InsightsSingleGeneral({ data }) {
  const page = data.wpPost
  return (
    <Layout
      bodyClassName='blog-template-default single single-blog postid-27063 biggest-changes-marketing-measurement app-data index-data singular-data single-data single-blog-data single-blog-biggest-changes-marketing-measurement-data'
      page={page}
    >
      <BannerAbstract />
      <Main page={page} />
    </Layout>
  )
}

const BannerAbstract = () => {
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip hero'>
      <section className='image'>
        <div className='figure'>
          <StaticImage
            src='../images/default-hypercube-hero_878b7ef8.jpg'
            alt=''
          />
        </div>
      </section>
    </section>
  )
}

const Main = ({ page }) => {
  const { postImage, postStickyCard, simplePost, postHubspotForm } = page

  return (
    <section className='single single--light single-blog multivariate-grid multivariate-grid--horizontal-clip insights'>
      <div className='single-blog__container multivariate-grid__container'>
        <div className='single-blog__main multivariate-grid__row'>
          <div className='single-blog__column multivariate-grid__column'>
            {postImage.image && <Image image={postImage.image} />}
            <PostTitleBlock page={page} />
            {!isObjectEmpty(postHubspotForm) && (
              <HubspotFormWrap content={postHubspotForm} />
            )}
            {simplePost.simplePost && (
              <SimplePost content={simplePost.simplePost} />
            )}
          </div>
          <div className='single-blog__column multivariate-grid__column'>
            <StickyCard content={postStickyCard} />
          </div>
        </div>
      </div>
    </section>
  )
}

const Image = ({ image }) => {
  return (
    <section className='image'>
      <div className='figure'>
        <ImageWrap image={image} />
      </div>
    </section>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      ...SeoPost
      ...RelatedBlockPost
      ...FormattedPostDate
      categories {
        nodes {
          name
          slug
        }
      }
      ...PostImage
      ...PostStickyCard
      ...PostHubspotForm
      simplePost {
        simplePost
      }
    }
  }
`
